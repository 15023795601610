/*============================================================================================================
-----Cleaner--------------------------------------------------------------------------------------------------
============================================================================================================*/
.clear {
  clear: both;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
.dblock {
  display: block;
}
.dnone {
  display: none;
}
.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pc {
  display: block;
  @include res('sp') {
    display: none;
  }
}
.sp {
  display: none;
  @include res('sp') {
    display: block;
  }
}

@mixin hide() {
  transition: 0.4s ease;
  opacity: 0;
  pointer-events: none;
}
@mixin visible() {
  transition: 0.4s ease;
  opacity: 1;
  pointer-events: auto;
}

@mixin scrollEffect() {
  opacity: 0;
  transition: 0.4s ease;
  transform: translateY(60px);
  &.inview {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/*============================================================================================================
-----Cleaner--------------------------------------------------------------------------------------------------
============================================================================================================*/
html,
body {
  height: 100%;
}

.hover--item {
  position: relative;
  // &:hover,&.active {
  // 	@include res('pc') {
  // 		.hover--item--en {
  // 			opacity: 0;
  // 		}
  // 		.hover--item--jp {
  // 			opacity: 1;
  // 		}
  // 	}
  // }
  &:hover {
    span {
      color: $orange;
    }
  }
  &--en {
    transition: 0.4s;
  }
  &--jp {
    display: block;
    transition: 0.4s;
    margin-top: 3px;
    @include fontJp();
  }
}

.arrow {
  background-size: cover;
  background-position: center;
  display: block;
  transition: 0.4s;
  transform-origin: center center;
  &-single {
    &--down,
    &--up {
      width: 14px;
      height: 12px;
    }
    &--left,
    &--right {
      width: 12px;
      height: 14px;
    }
    &--white {
      &.arrow-single--up {
        background-image: url(../img/arrow-single--white--up@2x.png);
      }
      &.arrow-single--down {
        background-image: url(../img/arrow-single--white--down@2x.png);
      }
      &.arrow-single--left {
        background-image: url(../img/arrow-single--white--left@2x.png);
      }
      &.arrow-single--right {
        background-image: url(../img/arrow-single--white--right@2x.png);
      }
    }
    &--blue {
      &.arrow-single--up {
        background-image: url(../img/arrow-single--blue--up@2x.png);
      }
      &.arrow-single--down {
        background-image: url(../img/arrow-single--blue--down@2x.png);
      }
      &.arrow-single--left {
        background-image: url(../img/arrow-single--blue--left@2x.png);
      }
      &.arrow-single--right {
        background-image: url(../img/arrow-single--blue--right@2x.png);
      }
    }
    &--orange {
      &.arrow-single--up {
        background-image: url(../img/arrow-single--orange--up@2x.png);
      }
      &.arrow-single--down {
        background-image: url(../img/arrow-single--orange--down@2x.png);
      }
      &.arrow-single--left {
        background-image: url(../img/arrow-single--orange--left@2x.png);
      }
      &.arrow-single--right {
        background-image: url(../img/arrow-single--orange--right@2x.png);
      }
    }
    &--brown {
      &.arrow-single--up {
        background-image: url(../img/arrow-single--brown--up@2x.png);
      }
      &.arrow-single--down {
        background-image: url(../img/arrow-single--brown--down@2x.png);
      }
      &.arrow-single--left {
        background-image: url(../img/arrow-single--brown--left@2x.png);
      }
      &.arrow-single--right {
        background-image: url(../img/arrow-single--brown--right@2x.png);
      }
    }
  }
  &-double {
    width: 22px;
    height: 14px;
    &--white {
      background-image: url(../img/arrow-double--white@2x.png);
    }
    &--orange {
      background-image: url(../img/arrow-double--orange@2x.png);
    }
    &--brown {
      background-image: url(../img/arrow-double--brown@2x.png);
    }
  }
}

a {
  &.flex {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

main {
  width: 100%;
  padding: 0 100px;
  @include res('sp') {
    padding: 0;
  }
  &.lower {
    padding-top: 60px;
    .lower_header {
      padding: 0;
      width: 100%;
      height: 350px;
      background-size: cover;
      background-position: center;
      justify-content: center;
      flex-direction: column;
      color: $white;
      position: relative;
      @include res('sp') {
        height: 200px;
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &_title {
        &--en {
          font-size: 18px;
          letter-spacing: 0.1em;
          font-weight: bold;
          margin-bottom: 20px;
          position: relative;
          z-index: 2;
          text-align: center;
          @include res('sp') {
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
        &--jp {
          font-size: 30px;
          letter-spacing: 0.2em;
          @include fontJp();
          font-weight: 700;
          line-height: 1.6em;
          position: relative;
          z-index: 2;
          text-align: center;
          width: 100%;
          @include res('sp') {
            font-size: 16px;
          }
        }
      }
    }
    &.service {
      .lower_header {
        background-image: url(../img/service_header@2x.png);
      }
    }
    &.about {
      .lower_header {
        background-image: url('../img/about_header@2x.jpg');
      }
    }
    &.record-gallery {
      .lower_header {
        background-image: url(../img/gallery_header@2x.png);
      }
    }
    &.flexidisc {
      .lower_header {
        background-image: url(../img/fd_header@2x.png);
      }
    }
    &.guide {
      .lower_header {
        background-image: url(../img/guide_header@2x.png);
      }
    }
    &.qa {
      .lower_header {
        background-image: url(../img/qa_header@2x.png);
        padding-bottom: 40px;
      }
    }
    &.template {
      .lower_header {
        background-image: url(../img/template_header@2x.png);
      }
    }
    &.blog {
      .lower_header {
        background-image: url(../img/blog_header@2x.png);
      }
    }
    &.contact {
      .lower_header {
        background-image: url(../img/contact_header@2x.png);
      }
    }
    &.term {
      .lower_header {
        background-image: url(../img/terms_header@2x.png);
      }
    }
    &.transaction {
      .lower_header {
        background-image: url(../img/transaction_header@2x.png);
      }
    }
    &.cutting {
      .lower_header {
        background-image: url(../img/cutting_header@2x.png);
      }
    }
  }
}

section {
  padding-top: 100px;
  padding-bottom: 100px;
  @include res('sp') {
    padding: 60px 20px;

    section {
      padding: 60px 0;
    }
  }
}

.link {
  width: 100%;
  text-align: center;
}
.flexidisc_price_list--item + .link {
  margin-top: 100px;

  @include res('sp') {
    margin-top: 60px;
  }
}

.link_button {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  &:hover {
    @include res('pc') {
      background-color: $white;
      .link_button--en {
        opacity: 0;
      }
      .link_button--jp {
        opacity: 1;
      }
    }
  }
  &--more {
    width: 250px;
    font-weight: normal;
    margin: 0 auto;
    @include res('sp') {
      width: 100%;
    }
    .link_button--en {
      font-weight: normal;
    }
  }
  &--rc {
    @include res('pc') {
      width: 530px;
      margin: 0 auto;
    }
    @include res('sp') {
      flex-direction: column;
      height: 100px;
    }
    &--en {
      margin-right: 30px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 1em;
      @include res('sp') {
        margin-right: 0;
        margin-bottom: 14px;
      }
    }
    &--jp {
      font-size: 14px;
      font-weight: bold;
      line-height: 1em;
    }
  }
  .arrow-single {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    @include res('sp') {
      left: 10px;
    }
  }
  .arrow-double {
    display: inline-block;
    margin-right: 10px;
    @include res('sp') {
      margin-right: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }
  }
  font-size: 16px;
  letter-spacing: 0.05em;
  border-radius: 7px;
  color: $white;
  &--en {
    transition: 0.4s;
    font-weight: bold;
  }
  &--jp {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.4s;
  }
  &--orange {
    background-color: $orange;
    border: 1px solid $orange;
    &:hover {
      @include res('pc') {
        color: $orange;
        p {
          color: $orange;
        }
        .arrow-double {
          background-image: url(../img/arrow-double--orange@2x.png);
        }
        .arrow-single {
          background-image: url(../img/arrow-single--orange--right@2x.png);
        }
      }
    }
  }
  &--blue {
    background-color: $blue;
    border: 1px solid $blue;
    &:hover {
      @include res('pc') {
        color: $blue;
        p {
          color: $blue;
        }
        .arrow-double {
          background-image: url(../img/arrow-double--blue@2x.png);
        }
        .arrow-single {
          background-image: url(../img/arrow-single--blue--right@2x.png);
        }
      }
    }
  }
  &--white {
    background-color: $white;
    border: 1px solid $white;
    &:hover {
      @include res('pc') {
        color: $white;
        p {
          color: $white;
        }
        .arrow-double {
          background-image: url(../img/arrow-double--white@2x.png);
        }
        .arrow-single {
          background-image: url(../img/arrow-single--white--right@2x.png);
        }
      }
    }
  }
  &--brown {
    background-color: $brown;
    border: 1px solid $brown;
    &:hover {
      @include res('pc') {
        color: $brown;
        p {
          color: $brown;
        }
        .arrow-double {
          background-image: url(../img/arrow-double--brown@2x.png);
        }
        .arrow-single {
          background-image: url(../img/arrow-single--brown--right@2x.png);
        }
      }
    }
  }
}

.title {
  text-align: center;
  padding-bottom: 20px;
  position: relative;
  color: $brown;
  /*
	&:after {
		content: '';
		display: block;
		width: 50px;
		height: 1px;
		background-color: $brown;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
	}
	&--en {
		font-size: 38px;
		font-weight: bold;
		letter-spacing: 0.05em;
		margin-bottom: 15px;
		line-height: 1em;
	}
	&--jp {
		@include fontJp();
		font-size: 12px;
		letter-spacing: 0.1em;
		line-height: 1.4em;
	}
	&--noDes {
		padding-bottom: 0;
		&:after {
			display: none;
		}
	}
	*/
}
.title--en {
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  line-height: 1em;
}
.title--jp {
  @include fontJp();
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 1.4em;
}
.title--noDes {
  padding-bottom: 0;
  &:after {
    display: none;
  }
}
.title--fs-24 {
  font-size: 24px;
}
.title--align-left {
  text-align: left;
}
.title--bold {
  font-weight: bold;
}

.title--align-left + p {
  margin-top: 18px;
}

.description {
  text-align: center;
  font-size: 16px;
  @include fontJp();
  letter-spacing: 0.1em;
  margin-top: 10px;
  @include res('sp') {
    letter-spacing: 0em;
    text-align: left;
  }
}

header {
  position: fixed;
  z-index: 99;
  @include res('sp') {
    width: 100%;
  }

  .header_contact {
    position: fixed;
    width: 100px;
    height: 100px;
    top: 60px;
    right: 0;
    @include res('sp') {
      width: 60px;
      height: 60px;
      top: 0;
    }
    a {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: $blue;
      align-items: center;
      justify-content: center;
      @include res('sp') {
        background-color: $brown;
        padding-right: 6px;
      }
      &:hover {
        @include res('pc') {
          background-color: $orange;
        }
        span {
          color: $white;
        }
        .hover--item--en {
          opacity: 0;
        }
        .hover--item--jp {
          opacity: 1;
        }
      }
      span {
        font-size: 12px;
        letter-spacing: 0.05em;
        font-weight: bold;
        color: $white;
      }
      .hover--item--jp {
        opacity: 0;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0;
      }
    }
  }
  .header_menu {
    height: 100%;
    @include res('sp') {
      width: 100%;
      height: 60px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &.active {
      .header_menu_bar {
        .header_menu_button {
          &_title {
            left: -1px;
            @include res('sp') {
              left: 0;
            }
          }
        }
        .arrow {
          &-single--down {
            transform: rotate(90deg) translateY(7px);
          }
          &-single--up {
            transform: rotate(-90deg) translateY(-7px);
          }
          &-single--right {
            transform: rotate(90deg) translateX(-6px);
          }
          &-single--left {
            transform: rotate(-90deg) translateX(6px);
          }
        }
      }
      .header_menu_content {
        left: 100px;
        @include res('sp') {
          left: auto;
          top: 60px;
        }
      }
    }
    &_bar {
      width: 100px;
      height: calc(100% - 160px);
      background-color: $cream;
      position: fixed;
      top: 160px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res('sp') {
        width: calc(100% - 120px);
        height: 60px;
        top: 0;
        left: 60px;
      }
      &:hover {
        @include res('pc') {
          cursor: pointer;
          .arrow {
            &-single--down {
              transform: rotate(90deg) translateY(7px);
            }
            &-single--up {
              transform: rotate(-90deg) translateY(-7px);
            }
          }
          .header_menu_button {
            &_title {
              transform: translateY(17px);
            }
            &_border {
              &--item {
                &:first-child {
                  top: -10px;
                }
                &:nth-child(2) {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
      .arrow {
        position: absolute;
        &-single--down {
          top: 28px;
          left: 50%;
          transform: translateX(-50%);
          @include res('sp') {
            display: none;
          }
        }
        &-single--up {
          bottom: 28px;
          left: 50%;
          transform: translateX(-50%);
          @include res('sp') {
            display: none;
          }
        }
        &-single--right {
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          @include res('pc') {
            display: none;
          }
        }
        &-single--left {
          top: 50%;
          transform: translateY(-50%);
          right: 10px;
          @include res('pc') {
            display: none;
          }
        }
      }
      .header_menu_button {
        width: 30px;
        @include res('sp') {
          width: auto;
        }
        &_title {
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 0.05em;
          line-height: 1em;
          margin-bottom: 5px;
          transition: 0.4s;
          position: relative;
          white-space: nowrap;
          @include res('sp') {
            font-size: 12px;
          }
        }
        &_border {
          width: 30px;
          height: 23px;
          position: relative;
          @include res('sp') {
            display: none;
          }
          &--item {
            width: 100%;
            height: 3px;
            background-color: $brown;
            position: absolute;
            left: 0;
            transition: 0.4s;
            &:first-child {
              top: 0;
            }
            &:nth-child(2) {
              top: 10px;
            }
            &:nth-child(3) {
              bottom: 0;
            }
          }
        }
      }
    }
    &_content {
      width: calc(100% - 200px);
      height: calc(100% - 60px);
      background-image: url(../img/menu_bg@2x.png);
      background-size: 154px 284px;
      background-color: $cream;
      position: fixed;
      top: 60px;
      left: -100%;
      transition: 0.5s 0.1s ease-in-out;
      z-index: -1;
      // flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-left: 50px;
      padding-right: 50px;
      @include res('sp') {
        width: 100%;
        height: calc(100% - 120px);
        left: 0;
        top: -100%;
        padding: 30px 10px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;
      }
      .flex--item {
        &.flex--left {
          @include res('pc') {
            width: 220px;
            margin-right: 37px;
          }
        }
        &.flex--right {
          @include res('pc') {
            width: 253px;
            margin-right: 30px;
          }
        }
        &:first-child {
          @include res('sp') {
            margin-bottom: 18px;
          }
        }
      }
      &_nav {
        // margin-bottom: 110px;
        @include res('pc') {
          align-items: flex-start;
        }
        @include res('sp') {
          margin-bottom: 15px;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
        &--item {
          padding-left: 33px;
          display: block;
          position: relative;
          @include res('sp') {
            padding-left: 20px;
            line-height: 1.45em;
          }
          &:not(:last-child) {
            margin-bottom: 35px;
            @include res('sp') {
              margin-bottom: 18px;
            }
          }
          &:hover {
            .arrow {
              opacity: 1;
            }
          }
          &.active {
            .arrow {
              opacity: 1;
            }
          }
          .arrow {
            position: absolute;
            left: 0;
            top: 6px;
            opacity: 0;
            transition: 0.4s;
          }
          .hover--item--jp {
            // left: 33px;
            font-size: 14px;
            letter-spacing: 0.1em;
            font-weight: 500;
          }
          span {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.05em;
            color: $brown;
            @include res('sp') {
              font-size: 20px;
            }
          }
        }
      }
      &_option {
        @include res('pc') {
          height: 547px;
        }
        @include res('sp') {
          margin-bottom: 44px;
        }
        &--item {
          padding-left: 33px;
          display: block;
          position: relative;
          @include res('sp') {
            padding-left: 20px;
          }
          &:not(:last-child) {
            margin-bottom: 30px;
            @include res('sp') {
              margin-bottom: 13px;
            }
          }
          &:hover {
            .arrow {
              opacity: 1;
            }
          }
          &.active {
            .arrow {
              opacity: 1;
            }
          }
          .arrow {
            position: absolute;
            left: 0;
            top: 6px;
            opacity: 0;
            transition: 0.4s;
          }
          span {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.05em;
            color: $brown;
            @include res('sp') {
              font-size: 12px;
            }
            &.hover--item--jp {
              font-weight: 500;
              @include res('sp') {
                line-height: 1em;
              }
            }
          }
        }
      }
      &_tel {
        text-align: center;
        margin: 0 auto;
        &_lead {
          font-size: 12px;
          letter-spacing: 0.05em;
          font-weight: bold;
          margin-bottom: 16px;
          @include fontJp();
          font-weight: 700;
        }
        &_num {
          font-size: 24px;
          color: $orange;
          letter-spacing: 0.05em;
          margin-bottom: 12px;
          font-weight: bold;
          display: inline-block;
        }
        &_time {
          font-size: 12px;
          @include fontJp();
          letter-spacing: 0.1em;
          font-weight: 700;
        }
      }
    }
  }
  .header_logo {
    position: fixed;
    width: 100px;
    height: 100px;
    top: 60px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    @include res('sp') {
      width: 60px;
      height: 60px;
      top: 0;
    }
    a {
      transition: 0.4s;
      &:hover {
        @include res('pc') {
          background-color: $brown;
        }
      }
      img {
        @include res('sp') {
          width: 38px;
          height: auto;
        }
      }
    }
  }
}
.header_order {
  width: 100px;
  height: calc(100% - 160px);
  position: fixed;
  top: 160px;
  right: 0;
  @include res('sp') {
    width: 100%;
    height: 60px;
    top: auto;
    bottom: 0;
    z-index: 999;
  }
  a {
    background-color: $orange;
    transition: 0.4s;
    @include res('sp') {
      padding: 0 32px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1em;
    }
    &:hover {
      @include res('pc') {
        background-color: $brown;
      }
    }
  }
  .arrow {
    position: absolute;
    &-single--down {
      top: 28px;
      left: 50%;
      transform: translateX(-50%);
      @include res('sp') {
        display: none;
      }
    }
    &-single--up {
      bottom: 28px;
      left: 50%;
      transform: translateX(-50%);
      @include res('sp') {
        display: none;
      }
    }
    &-single--right {
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      @include res('pc') {
        display: none;
      }
    }
    &-single--left {
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
      @include res('pc') {
        display: none;
      }
    }
  }
  &_jp {
    font-size: 10px;
    color: $white;
    @include fontJp();
    @include res('pc') {
      position: absolute;
      top: 66px;
      left: 50%;
      transform: translateX(-50%);
      line-height: 1.7em;
    }
    @include res('sp') {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 700;
      br {
        display: none;
      }
    }
  }
  &_en {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.05em;
    color: $white;
    @include res('pc') {
      writing-mode: vertical-rl;
    }
    @include res('sp') {
      font-size: 16px;
    }
  }
}

.image_list {
  @include res('pc') {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 40px;
  }
  @include res('sp') {
    width: 100%;
  }
  &--item {
    min-height: 400px;
    @include res('pc') {
      align-items: stretch;
    }
    @include res('sp') {
      height: auto;
      flex-direction: column;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
      @include res('sp') {
        margin-bottom: 50px;
      }
    }
    &:nth-child(even) {
      .image_list--item_img {
        @include res('pc') {
          border-radius: 0 10px 10px 0;
        }
      }
      .image_list--item_content {
        @include res('pc') {
          border-radius: 10px 0 0 10px;
        }
      }
      @include res('sp') {
        flex-direction: column-reverse;
      }
    }
    &_img {
      width: 50%;
      height: auto;
      background-position: center;
      background-size: cover;
      border-radius: 10px 0 0 10px;
      @include res('sp') {
        width: 100%;
        height: 290px;
        border-radius: 10px 10px 0 0;
      }
    }
    &_content {
      width: 50%;
      padding: 26px 34px 50px 26px;
      position: relative;
      background-color: $grey;
      @include res('pc') {
        border-radius: 0 10px 10px 0;
      }
      @include res('sp') {
        width: 100%;
        padding: 23px 10px 28px 10px;
        border-radius: 0 0 10px 10px;
      }
      .link {
        @include res('pc') {
          position: absolute;
          bottom: 50px;
          left: 50%;
          transform: translateX(-50%);
        }
        @include res('sp') {
          width: calc(100% + 20px);
          padding: 0;
          position: relative;
          left: -10px;
          margin-top: 28px;
        }
      }
    }
  }
}

.sec_rc {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  @include res('pc') {
    padding: 0 100px;
  }
  &_content {
    @include res('pc') {
      max-width: 1080px;
      margin: 0 auto;
      padding: 0 40px;
    }
    @include res('sp') {
      padding: 0 20px;
    }
    &_area {
      width: 100%;
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
      .description {
        @include res('sp') {
          // padding: 0 20px;
        }
      }
      &.no-padding {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    &_link {
      flex-wrap: wrap;
      margin-top: 58px;
      @include res('sp') {
        width: 100%;
        margin-top: 52px;
      }
      &--item {
        width: calc((100% - 20px) / 2);
        height: 120px;
        color: $brown;
        border-radius: 10px;
        border: none;
        &:nth-child(n + 3) {
          margin-top: 20px;
        }
        &:hover {
          @include res('pc') {
            background-color: $brown;
            color: $white;
            .arrow {
              background-image: url(../img/arrow-single--white--right@2x.png);
            }
          }
        }
        &_text {
          &_option {
            margin-bottom: 10px;
            @include res('sp') {
              margin-bottom: 8px;
              line-height: 1em;
            }
            span {
              transition: 0.4s;
              &:first-child {
                font-size: 24px;
                letter-spacing: 0.05em;
                font-weight: bold;
                @include res('sp') {
                  display: block;
                  font-size: 18px;
                  text-align: center;
                  margin-bottom: 8px;
                }
              }
              &:nth-child(2) {
                font-size: 14px;
                letter-spacing: 0.1em;
                @include fontJp();
                @include res('sp') {
                  display: block;
                  font-size: 12px;
                  text-align: center;
                }
              }
            }
          }
          &_price {
            span {
              transition: 0.4s;
              &:first-child {
                font-size: 42px;
                letter-spacing: 0.05em;
                font-weight: bold;
                @include res('sp') {
                  font-size: 24px;
                }
              }
              &:nth-child(2) {
                font-size: 18px;
                letter-spacing: 0.1em;
                margin-left: -5px;
                @include res('sp') {
                  font-size: 14px;
                  margin-left: -7px;
                }
              }
            }
          }
        }
      }
    }
    &_attention {
      text-align: right;
      margin-top: 18px;
      font-size: 12px;
      letter-spacing: 0.1em;
      @include fontJp();
      line-height: 1em;
    }
    .link {
      margin-top: 30px;
      @include res('sp') {
        margin-top: 28px;
      }
    }
  }
  &--link {
    .link {
      margin-top: 56px;
      @include res('sp') {
        margin-top: 46px;
      }
    }
  }
}

// Bg設定
.sec_rc_bg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
}
.sec_rc_bg_inner {
  width: 100vw;
  position: absolute;
  left: -50vw;
  top: 0;
  transform: translate(0, -50%);
  height: 100px;
}
.sec_rc_bg_inner_block {
  height: 334px;
  background-image: url('../img/rc_bg.png');
  background-size: 154px 334px;
  background-position: center;
}
.sec_rc_bg_inner_block + .sec_rc_bg_inner_block {
  margin-top: -50px;
}
.sec_rc_content {
  height: 100%;
  display: flex;
  align-items: center;
}

.sec_rc_wrap + footer {
  margin-top: -50px;
}
.ProfileModal + footer {
  margin-top: -50px;
}
.GalleryModal + footer {
  margin-top: -50px;
}

footer {
  z-index: 0;
  background-color: #0f0;
  padding-top: 100px;
}

main.top + .sec_rc_wrap {
  margin-top: -50px;
}
main.record-gallery + .sec_rc_wrap {
  margin-top: -50px;
}

main.guide + .sec_rc_wrap {
  margin-top: -50px;
}

main.flexidisc + .sec_rc_wrap {
  margin-top: -50px;
}

// main.service + .sec_rc_wrap,.main.record-gallery + .sec_rc_wrap {
// 	@include res('pc') {
// 		& + footer {
// 			margin-top: 0;
// 			position: relative;
// 			z-index: -1;
// 			padding-top: 60px;
// 			&:before {
// 				content: '';
// 				display: block;
// 				width: 100%;
// 				height: 50px;
// 				background-color: $blue;
// 				position: absolute;
// 				top: -50px;
// 				left: 0;
// 			}
// 		}
// 	}
// }

.guide_list {
  margin-top: 56px;
  @include res('sp') {
    margin-top: 35px;
  }
  &--item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    @include res('pc') {
      max-width: 1080px;
      height: 400px;
      margin: 0 auto;
      padding: 0 40px;
    }
    @include res('sp') {
      flex-direction: column;
    }
    &:nth-child(odd) {
      @include res('pc') {
        flex-direction: row-reverse;
      }
    }
    &_img {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res('sp') {
        width: 100%;
        height: 250px;
      }
    }
    &_content {
      width: 50%;
      height: 100%;
      padding: 24px 20px;
      @include res('sp') {
        width: 100%;
        height: auto;
        padding: 10px;
      }
      &_step {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
        @include res('sp') {
          font-size: 20px;
          margin-bottom: 9px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        span {
          display: inline-block;
          width: 150px;
          height: 30px;
          background-color: $orange;
          border-radius: 5px;
          font-size: 12px;
          @include fontJp();
          font-weight: 500;
          text-align: center;
          letter-spacing: 0.1em;
          vertical-align: middle;
          color: $white;
          margin-left: 21px;
          padding: 8px 0 10px;
          line-height: 1.2em;
        }
      }
      &_title {
        font-size: 24px;
        @include fontJp();
        letter-spacing: 0.1em;
        font-weight: 700;
        margin-bottom: 20px;
        @include res('sp') {
          font-size: 20px;
          margin-bottom: 24px;
        }
      }
      &_description {
        @include fontJp();
        font-size: 14px;
        letter-spacing: 0.1em;
        @include res('sp') {
          font-size: 16px;
        }
        a {
          color: $orange;
          &:hover {
            @include res('pc') {
              text-decoration: underline;
            }
          }
        }
        span {
          @include res('pc') {
            font-size: 10px;
          }
          @include res('sp') {
            font-size: 12px;
          }
        }
      }
    }
  }
}

footer {
  background-color: $blue;
  @include res('pc') {
    padding: 110px 100px 40px 100px;
  }
  @include res('sp') {
    padding-top: 88px;
    padding-bottom: 89px;
  }
  .footer_content {
    @include res('pc') {
      max-width: 1080px;
      margin: 0 auto;
      padding: 0 40px;
    }
    position: relative;
    @include res('sp') {
      width: 100%;
      padding: 0 10px;
    }
    .footer_logo {
      margin-bottom: 12px;
      @include res('sp') {
        padding: 0 10px;
        img {
          width: auto;
          height: 22px;
        }
      }
    }
    .footer_tel {
      margin-bottom: 40px;
      @include res('sp') {
        padding: 0 10px;
      }
      p {
        span {
          &:first-child {
            font-size: 20px;
            letter-spacing: 0.05em;
            color: $orange;
            font-weight: bold;
            @include res('sp') {
              display: block;
              font-size: 24px;
              margin-bottom: 10px;
            }
            a {
              color: $orange;
              &:hover {
                @include res('pc') {
                  text-decoration: underline;
                }
              }
            }
          }
          &:nth-child(2) {
            font-size: 10px;
            color: $white;
            letter-spacing: 0.1em;
            @include fontJp();
            position: relative;
            top: -2px;
            @include res('sp') {
              top: auto;
              font-size: 12px;
            }
          }
        }
      }
    }
    .footer_nav {
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 45px;
      @include res('sp') {
        padding: 0 10px;
        margin-bottom: 53px;
        flex-direction: column;
      }
      .flex--item {
        position: relative;
        &:not(:last-child) {
          @include res('pc') {
            margin-right: 30px;
          }
          @include res('sp') {
            margin-bottom: 20px;
          }
        }
        &:before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: $white;
          position: absolute;
          top: 0;
          left: 80px;
          @include res('sp') {
            display: none;
          }
        }
        &:nth-child(3) {
          @include res('pc') {
            &:before {
              left: 114px;
            }
            .footer_nav--item {
              &--en {
                width: 125px;
                font-size: 10px;
              }
            }
          }
        }
      }
      &--item {
        display: block;
        line-height: 1em;
        &:not(:last-child) {
          margin-bottom: 20px;
          @include res('sp') {
            margin-bottom: 20px;
          }
        }
        span {
          color: $white;
          vertical-align: top;
          transition: 0.4s;
        }
        &--en {
          font-size: 12px;
          letter-spacing: 0.05em;
          display: inline-block;
          width: 90px;
          @include res('sp') {
            display: none;
          }
        }
        &--jp {
          font-size: 10px;
          @include fontJp();
          @include res('sp') {
            font-size: 12px;
          }
        }
        &:hover {
          @include res('pc') {
            .footer_nav--item--en {
              color: $orange;
            }
            .footer_nav--item--jp {
              color: $orange;
            }
          }
        }
      }
    }
    .footer_sns {
      width: 140px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      @include res('pc') {
        @include res('<', 1280) {
          right: 40px;
        }
      }
      @include res('sp') {
        width: 186px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        bottom: auto;
        left: auto;
        margin-bottom: 42px;
      }
      &--item {
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white, 0);
        transition: 0.4s;
        @include res('sp') {
          width: 50px;
          height: 50px;
          margin: 0 6px;
        }
        svg {
          path {
            transition: 0.4s;
          }
        }
        &:hover {
          @include res('pc') {
            background-color: $white;
            svg {
              path {
                fill: $blue;
              }
            }
          }
        }
      }
    }
    .footer_copyright {
      font-size: 10px;
      letter-spacing: 0.1em;
      color: $white;
      @include fontJp();
      font-weight: 700;
      line-height: 1.4em;
      @include res('sp') {
        text-align: center;
      }
    }
  }
}

.show-pc {
  display: block;
  @include res('sp') {
    display: none;
  }
}
.show-sp {
  display: none;
  @include res('sp') {
    display: block;
  }
}

.sec_blog--bg-gray {
  background-color: #f7f7f7;
  .sec_blog_scroll {
    background-color: #fff;
  }
}
