.GalleryModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #f00;
  z-index: 1000;

  opacity: 0;
  display: none;
}

.GalleryModalCard {
  position: relative;
  height: calc(100% - 200px);
}

.GalleryModalCard__thumb {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px 0 10px 10px;
  background-color: #666;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.BtnCloseGalleryModal {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 60px;
  height: 60px;
  border: 1px solid #ec732e;
  border-radius: 6px 6px 0 0;
  background-color: #fff;

  transition: background 0.3s ease-out;

  &:hover {
    background-color: #ec732e;
    transition: background 0s ease-out;
    .BtnProfileCardClose__icon {
      &::before,
      &::after {
        background-color: #fff;
        transition: background 0s ease-out;
      }
    }
  }
}
