.ModalScroller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.ModalScroller__inner {
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: 120px;
  padding-right: 120px;

  @include res('sp') {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ModalScroller__inner--center {
  align-items: center;
  justify-content: center;
}
