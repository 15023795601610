.ticker {
  width: 100%;
  height: 60px;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: $grey;
  padding: 22px 0 21px;

  span {
    font-size: 14px;
    letter-spacing: 0.1em;
    color: $brown;
    display: inline-block;
    // float: left;
    word-break: keep-all;
    white-space: nowrap;
    // padding: 0 8px;
    padding-right: 8px;
    display: block;
  }

  @include res("sp") {
    display: none;
  }
}
.ticker_text {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  left: 0;
}

.tickerSlider {
  line-height: 1;
  display: flex;
  opacity: 0;
}
