.ContactBlock {
  background-color: #f7f7f7;
  border-radius: 7px 7px;
  padding: 108px 40px 100px 40px;
  margin: 0 0 60px 0;

  &.sec_contact_content {
    max-width: 100%;
    margin-top: 0;

    & > .sec_contact_form {
      margin-top: 0;
      br {
        display: block !important;
      }
      label br {
        display: none !important;
      }
      & > .wpcf7 {
        max-width: 670px;
        margin: 62px auto 0 auto;
      }
    }
  }

  @include res('sp') {
    padding: 40px 20px 40px 20px;
  }
}
