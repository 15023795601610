.Img {
  position: relative;
}

.Img--member {
  padding-top: 95%;
  background-color: #d9d9d9;
  border-radius: 10px 10px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
