.sec_blog_list_area {
  display: flex;
  justify-content: flex-start;
}

.sec_blog_list.sp-no-scroll {
  @include res("sp") {
    margin-left: -20px;
    margin-right: -20px;
    overflow: visible;
    width: var(--vw) !important;
  }
}

.sec_blog_list--sp-fullwidth {
  @include res("sp") {
    margin-left: -20px;
    margin-right: -20px;
    width: var(--vw) !important;

    & + .sec_blog_scroll {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.sec_blog_list--item {
  min-width: 300px;
  flex-shrink: 0;
  float: none;
  width: 380px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 !important;

  @include res("sp") {
    width: var(--vw) !important;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 !important;
  }
}

.sec_blog_list--item--cutting {
  min-height: 180px; //240px;
}

.sp-no-scroll {
  .sec_blog_list--item--cutting + .sec_blog_list--item--cutting {
    @include res("sp") {
      margin-top: 20px !important;
    }
  }
}

.sec_blog_list--item--gallery {
  width: 380px;
  height: 180px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  min-height: initial;

  a {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
  }

  @include res("sp") {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.sec_blog_list--item--gallery + .sec_blog_list--item--gallery {
  @include res("sp") {
    margin-top: 10px !important;
  }
}

.sec_blog_scroll_thumb {
  cursor: pointer;
}

.sec_blog_scroll {
  position: relative;
}
