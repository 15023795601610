.Grid {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;

  .Grid__col {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include res("sp") {
    flex-wrap: wrap;
  }
}

.Grid--half {
  .Grid__col {
    width: calc(50% - 20px);
  }
}
.Grid--cutting-team {
  margin-left: -16px;
  margin-right: -16px;
  .Grid__col {
    width: calc(25% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }

  @include res("sp") {
    margin-left: -10px;
    margin-right: -10px;
    .Grid__col {
      width: calc(50% - 20px);
      margin-left: 10px;
      margin-right: 10px;

      &:nth-child(n + 3) {
        margin-top: 33px;
      }
    }
  }
}
.title + .Grid--cutting-team {
  margin-top: 50px;

  @include res("sp") {
    margin-top: 32px;
  }
}
.Grid__col {
  .blog_category_nav_list {
    padding: 0;
  }
}

.Grid--sp-collapse {
  margin-left: 0;
  margin-right: 0;

  .Grid__col {
    width: 100%;
  }
}
