.service {
  &_wwh {
    &--item {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 76px;
        @include res('sp') {
          margin-bottom: 60px;
        }
      }
      .description {
        text-align: center;
        font-size: 24px;
        line-height: 2;
        @include res('<', 1180) {
          br {
            display: none;
          }
        }
        @include res('sp') {
          text-align: left;
          font-size: 20px;
          letter-spacing: 0.05em;
          br {
            // display: block;
          }
        }
      }
    }
  }
  &_hospitality {
    // background-color: $grey;
    &_list {
      @include res('pc') {
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 40px;
        padding-right: 40px;
      }
      margin-top: 56px;
      @include res('sp') {
        margin-top: 28px;
      }
      &--item {
        @include res('pc') {
          align-items: stretch;
        }
        &:nth-child(2),
        &:nth-child(3) {
          .service_hospitality_list--item_content_title {
            letter-spacing: 0.05em;
          }
        }
        &_img {
          height: auto;
          @include res('sp') {
            height: 290px;
          }
        }
        &_content {
          background-color: $grey;
          @include res('pc') {
            overflow: visible;
          }
          &_point {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 9px;
            }
          }
          &_title {
            font-size: 24px;
            @include fontJp();
            letter-spacing: 0.1em;
            margin-bottom: 23px;
            font-weight: 700;
            line-height: 1.5em;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 20px;
              br {
                display: none;
              }
            }
          }
          &_description {
            font-size: 14px;
            letter-spacing: 0.1em;
            @include fontJp();
            @include res('sp') {
              font-size: 16px;
            }
            span {
              font-size: 10px;
              @include fontJp();
              @include res('sp') {
                font-size: 12px;
              }
            }
          }
          .link {
            @include res('pc') {
              position: relative;
              margin: 0 auto;
              bottom: auto;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
  &_variety {
    // background-color: $grey;
    &_list {
      @include res('pc') {
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 40px;
        padding-right: 40px;
      }
      margin-top: 56px;
      @include res('sp') {
        margin-top: 28px;
      }
      &--item {
        @include res('pc') {
          align-items: stretch;
        }
        &:nth-child(2),
        &:nth-child(3) {
          .service_hospitality_list--item_content_title {
            letter-spacing: 0.05em;
          }
        }
        &_img {
          height: auto;
          @include res('sp') {
            height: 290px;
          }
        }
        &_content {
          background-color: $grey;
          @include res('pc') {
            overflow: visible;
          }
          &_point {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 9px;
            }
          }
          &_title {
            font-size: 24px;
            @include fontJp();
            letter-spacing: 0.1em;
            margin-bottom: 23px;
            font-weight: 700;
            line-height: 1.5em;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 20px;
              br {
                display: none;
              }
            }
          }
          &_description {
            font-size: 14px;
            letter-spacing: 0.1em;
            @include fontJp();
            @include res('sp') {
              font-size: 16px;
            }
            span {
              font-size: 10px;
              @include fontJp();
              @include res('sp') {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  &_quality {
    background-color: $grey;
    &_list {
      margin-top: 56px;
      @include res('pc') {
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 40px;
        padding-right: 40px;
      }
      @include res('sp') {
        margin-top: 28px;
      }
      &--item {
        &:nth-child(2) {
          .service_quality_list--item_content {
            @include res('sp') {
              padding-bottom: 0;
            }
          }
        }
        &_content {
          background-color: $white;
          @include res('pc') {
            // overflow: scroll;
          }
          &_point {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 9px;
            }
          }
          &_title {
            font-size: 24px;
            @include fontJp();
            letter-spacing: 0.05em;
            margin-bottom: 23px;
            font-weight: 700;
            line-height: 1.5em;
            @include res('sp') {
              font-size: 20px;
              margin-bottom: 20px;
              br {
                display: none;
              }
            }
          }
          &_description {
            font-size: 14px;
            letter-spacing: 0.1em;
            @include fontJp();
            @include res('sp') {
              font-size: 16px;
            }
            span {
              font-size: 10px;
              @include fontJp();
              @include res('sp') {
                font-size: 12px;
              }
            }
          }
          .link {
            @include res('pc') {
              position: relative;
              margin: 0 auto;
              bottom: auto;
              margin-top: 70px;
            }
          }
        }
      }
    }
    &_variation {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 44px;
      }
      @include res('sp') {
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
      }
      &_text {
        &_point {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 12px;
          @include res('sp') {
            font-size: 20px;
            margin-bottom: 9px;
          }
        }
        &_title {
          font-size: 24px;
          @include fontJp();
          letter-spacing: 0.1em;
          margin-bottom: 23px;
          font-weight: 700;
          @include res('sp') {
            font-size: 20px;
            margin-bottom: 20px;
            br {
              display: none;
            }
          }
        }
        &_description {
          font-size: 14px;
          letter-spacing: 0.1em;
          @include fontJp();
          @include res('sp') {
            font-size: 16px;
          }
          span {
            font-size: 10px;
            @include fontJp();
            @include res('sp') {
              font-size: 12px;
            }
          }
        }
      }
      &_list {
        flex-wrap: wrap;
        align-items: flex-start;
        @include res('pc') {
          max-width: 930px;
          margin: 0 auto;
          margin-top: 78px;
          padding: 0 40px;
        }
        @include res('sp') {
          width: 100%;
          padding: 0 20px;
          margin-top: 56px;
        }
        &--item {
          width: 130px;
          margin: 0 20px;
          text-align: center;
          @include res('sp') {
            width: calc((100% - 40px) / 2);
            margin: 0;
          }
          @include res('pc') {
            margin-bottom: 50px;
          }
          &:nth-child(n + 3) {
            @include res('sp') {
              margin-top: 50px;
            }
          }
          &_img {
            margin-bottom: 15px;
            &.full {
              img {
                object-fit: contain;
              }
            }
          }
          &_text {
            font-size: 12px;
            letter-spacing: 0.1em;
            @include fontJp();
            width: calc(100% + 20px);
            position: relative;
            left: -10px;
            text-align: center;
          }
        }
      }
      &_color {
        margin-top: 58px;
        @include res('sp') {
          margin-top: 50px;
          padding: 0 10px;
        }
        &_title {
          font-size: 18px;
          text-align: center;
          font-weight: bold;
          letter-spacing: 0.05em;
          margin-bottom: 25px;
          @include res('sp') {
            margin-bottom: 44px;
          }
        }
        &_img {
          text-align: center;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

.service_intro {
  margin: 100px auto 90px auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
