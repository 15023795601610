/*

HTMLタグのデフォルト定義

*/
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
}

body {
  font-family: $font-family;
  margin: 0;
  padding: 0;

  color: $brown;
  line-height: 1.8em;
}
a {
  color: $brown;
}

.ContentWidth {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}
