.ProfileModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #f00;
  z-index: 1000;

  opacity: 0;
  display: none;
}

.ProfileModal__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  display: block;
}

.ProfileCardSwitcher {
  width: 100%;
}

.ProfileCard {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;

  @include res("sp") {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.ProfileCard__inner {
  border-radius: 10px 10px;
  display: flex;
  background-color: #fff;
  overflow: hidden;
  position: relative;

  @include res("sp") {
    flex-direction: column;
  }
}

.ProfileCard__figure {
  width: 50%;
  background-color: #333;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include res("sp") {
    width: 100%;
    padding-top: 85.29%;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.ProfileCard__body {
  width: 50%;
  padding-top: 23px;
  padding-bottom: 24px;
  padding-left: 30px;
  padding-right: 24px;

  @include res("sp") {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.ProfileCard__position--en {
  // @include fontJp();
  font-weight: bold;
  font-size: 24px;

  @include res("sp") {
    font-size: 20px;
  }
}
.ProfileCard__position--ja {
  @include fontJp();
  font-weight: bold;
  font-size: 24px;
  margin-top: 10px;
}
.ProfileCard__name {
  @include fontJp();
  font-weight: bold;
  font-size: 24px;
  margin-top: 10px;
}
.ProfileCard__text {
  font-size: 16px;
  line-height: 1.75;
  margin-top: 23px;
}

.BtnProfileCardClose {
  position: absolute;
  top: 23px;
  right: 23px;
  border-radius: 8px 8px;
  border: 1px solid #ec732e;
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  transition: background 0.3s ease-out;

  &:hover {
    background-color: #ec732e;
    transition: background 0s ease-out;
    .BtnProfileCardClose__icon {
      &::before,
      &::after {
        background-color: #fff;
        transition: background 0s ease-out;
      }
    }
  }
}
.BtnProfileCardClose__icon {
  width: 100%;
  height: 14px;
  position: relative;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    display: block;
    height: 20px;
    width: 2px;
    position: absolute;
    background-color: #ec732e;
    margin-left: -2px;
    top: 50%;
    left: 50%;
    transition: background 0.3s ease-out;
    backface-visibility: hidden;
  }
  &::before {
    transform: translate(0, -50%) rotate(30deg);
  }
  &::after {
    transform: translate(0, -50%) rotate(-30deg);
  }
}

.BtnMemberList {
  display: block;
  text-align: left;
  width: 100%;
  transition: opacity 0.7s ease-out;
  color: #2f1829;

  p {
    color: #2f1829;
  }

  &:hover {
    opacity: 0.65;
    transition: opacity 0.1s ease-out;
    text-decoration: underline;
  }

  @include res("sp") {
    .about_member_list--item_position--en {
      font-size: 14px;
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .about_member_list--item_position--jp {
      font-size: 10px;
      margin-bottom: 8px;
    }
    .about_member_list--item_name {
      font-size: 12px;
    }
  }
}
