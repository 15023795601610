.top_visual {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 60px 100px 0;
  @include res('sp') {
    padding: 60px 0 0;
    height: calc(100% - 60px);
  }
  &_content {
    position: absolute;
    z-index: 2;
    top: calc(50%);
    margin-left: 50px;
    transform: translateY(-50%);
    color: $white;
    @include res('sp') {
      width: 100%;
      transform: translateY(-50%);
      margin-left: 0;
      padding: 0 20px;
    }
    &_copy {
      font-size: 56px;
      letter-spacing: 0.1em;
      margin-bottom: 14px;
      font-weight: bold;
      line-height: 1.1em;
      text-shadow: 0 0 3px rgba($black, 0.3);
      @include res('sp') {
        width: calc(100% + 20px);
        position: relative;
        left: -10px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 12px;
        text-indent: -5px;
        line-height: 1.2em;
      }
    }
    &_lead {
      font-size: 16px;
      letter-spacing: 0.2em;
      margin-bottom: 22px;
      text-shadow: 0 0 3px rgba($black, 0.3);
      @include res('sp') {
        margin-bottom: 20px;
        text-align: center;
        text-indent: 6px;
      }
    }
    .link_button {
      width: 320px;
      height: 100px;
      font-weight: bold;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 62px;
      @include res('sp') {
        width: 100%;
      }
      .arrow-double {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .link_button--en {
      }
      .link_button--jp {
        opacity: 1;
        position: relative;
        left: auto;
        transform: translateX(0);
      }
      &:hover {
        .link_button--en {
          opacity: 1;
        }
      }
    }
    .link_button--sp-disabled {
      @include res('sp') {
        display: none;
      }
    }
    .link_button--campaign {
      position: relative;
      padding-left: 0;
      height: auto;
      width: 320px;
      margin-left: 0;
      margin-right: auto;

      @include res('sp') {
        margin-left: auto;
      }

      @include res('<', 375) {
        width: 100%;
      }

      .height {
        width: 100%;
        position: relative;
        padding-top: 38.12%;
      }

      span {
        border-radius: 7px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      &:hover {
        transition: opacity 0.2s ease-out;
        opacity: 0.7;
      }
    }
    .link_button + .link_button--campaign {
      margin-top: 20px;
    }
  }
  &_slider {
    width: 100%;
    height: 100%;
    .slick-list {
      width: 100%;
      height: 100%;
    }
    .slick-track {
      height: 100%;
    }
    &--item {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
    .slide-animation {
      animation: slideZoom 15s 0s;
    }
    @keyframes slideZoom {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    }
    .slick-dots {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      li {
        width: 20px;
        height: 20px;
        margin: 0 10px;
        display: inline-block;
        &.slick-active {
          button {
            background-color: $orange;
          }
        }
        button {
          width: 100%;
          height: 100%;
          background-color: $cream;
          transition: 0.4s;
          border-radius: 10px;
          color: rgba($white, 0);
          &:hover {
            background-color: $orange;
          }
        }
      }
    }
  }
  .top_scroll {
    position: absolute;
    width: 68px;
    height: 49px;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    p {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.1em;
      margin-bottom: -4px;
      color: $white;
      white-space: nowrap;
    }
    img {
      animation: scrollIcon 3s ease-in-out infinite;
      @keyframes scrollIcon {
        0% {
          transform: translate(0, 0px);
        }
        15% {
          transform: translate(0, 5px);
        }
        30% {
          transform: translate(0, -2px);
        }
        45% {
          transform: translate(0, 5px);
        }
        60% {
          transform: translate(0, -2px);
        }
        75% {
          transform: translate(0, 0px);
        }
        100% {
          transform: translate(0, 0px);
        }
      }
    }
  }
}

.sec_service {
  &_content {
    @include res('pc') {
      max-width: 956px;
      margin: 0 auto;
      padding: 0 40px;
    }
    @include res('sp') {
      width: 100%;
      padding: 0 10px;
    }
    .sec_service_list {
      margin-top: 82px;
      margin-bottom: 93px;
      @include res('sp') {
        margin-top: 56px;
        margin-bottom: 53px;
        flex-direction: column;
      }
      &--item {
        @include res('pc') {
          @include res('<', 1156) {
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
        @include res('sp') {
          width: 100%;
        }
        &:not(:last-child) {
          @include res('sp') {
            margin-bottom: 52px;
          }
        }
        &:first-child {
          @include res('pc') {
            width: 238px;
          }
        }
        &:nth-child(2) {
          @include res('pc') {
            width: 264px;
          }
        }
        &:nth-child(3) {
          @include res('pc') {
            width: 252px;
          }
        }
        &_img {
          @include res('pc') {
            height: 131px;
          }
          justify-content: center;
          margin-bottom: 41px;
          @include res('sp') {
            margin-bottom: 30px;
          }
        }
        &_title {
          font-size: 24px;
          letter-spacing: 0.05em;
          margin-bottom: 13px;
          text-align: center;
          font-weight: bold;
        }
        &_text {
          @include fontJp();
          font-size: 12px;
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
    }
  }
}

.top_cut-fd {
  &_list {
    margin-top: 30px;
    @include res('sp') {
      margin-top: 35px;
    }
    &--item {
      &_content {
        @include res('sp') {
          padding-bottom: 0;
        }
        &_title {
          &--en {
            font-size: 38px;
            letter-spacing: 0.05em;
            margin-bottom: 14px;
            font-weight: bold;

            @include res('sp') {
              margin-bottom: 0;
              line-height: 1.26;
            }
          }
          &--jp {
            font-size: 12px;
            letter-spacing: 0.1em;
            margin-bottom: 20px;
            @include res('sp') {
              margin-bottom: 14px;
            }
          }
        }
        &_description {
          font-size: 16px;
          letter-spacing: 0.1em;
        }
      }
      &_img {
        overflow: hidden;
        // opacity: 0.4;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.top_guide {
  background-color: $grey;
  &_content {
    @include res('pc') {
      max-width: 936px;
      margin: 0 auto;
      padding: 0 40px;
    }
    .top_guide_list {
      margin-top: 82px;
      margin-bottom: 93px;
      @include res('sp') {
        margin-top: 58px;
        flex-direction: column;
        margin-bottom: 52px;
      }
      &--item {
        &:first-child {
          @include res('pc') {
            width: 238px;
          }
        }
        &:nth-child(2) {
          @include res('pc') {
            width: 264px;
          }
        }
        &:nth-child(3) {
          @include res('pc') {
            width: 226px;
          }
        }
        &:not(:last-child) {
          @include res('sp') {
            margin-bottom: 52px;
          }
        }
        &_img {
          @include res('pc') {
            height: 142px;
          }
          justify-content: center;
          margin-bottom: 21px;
          @include res('sp') {
            margin-bottom: 36px;
          }
        }
        &_title {
          font-size: 24px;
          letter-spacing: 0.05em;
          margin-bottom: 13px;
          text-align: center;
          font-weight: bold;
        }
        &_text {
          @include fontJp();
          font-size: 12px;
          letter-spacing: 0.1em;
          text-align: center;
        }
      }
    }
  }
}

.top_gallery {
  &_list {
    width: 100%;
    overflow: scroll;
    margin-top: 47px;
    margin-bottom: 60px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include res('sp') {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    &_area {
    }
    &--item {
      width: 360px;
      height: 180px;
      border-radius: 10px;
      position: relative;
      background-size: cover;
      background-position: center;
      margin: 0 10px;
      float: left;
      @include res('sp') {
        width: 100%;
        float: none;
        margin: 0;
      }
      &:not(:last-child) {
        @include res('sp') {
          margin-bottom: 10px;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        transition: 0.4s;
      }
      &:hover {
        @include res('pc') {
          &:before {
            background-color: rgba($orange, 0.7);
          }
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: flex-start;
        justify-content: center;
        padding: 0 20px;
        flex-direction: column;
      }
      &_title {
        color: $white;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
      }
    }
  }
  &_scroll {
    height: 10px;
    background-color: $grey;
    @include res('pc') {
      width: calc(100% - 80px);
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
    @include res('sp') {
      display: none;
    }
    &_thumb {
      width: 500px;
      height: 100%;
      background-color: $orange;
    }
  }
}

.sec_about {
  color: $white;
  background-position: center;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  &_content {
    position: relative;
    z-index: 1;
  }
  .title {
    &--en {
      color: $white;
    }
    &--jp {
      color: $white;
    }
    &:after {
      background-color: $white;
    }
  }
  .description {
    margin-bottom: 100px;
    @include res('sp') {
      margin-bottom: 52px;
    }
  }
}

.sec_blog {
  &_list {
    margin-top: 54px;
    margin-bottom: 54px;
    width: 100%;
    overflow: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include res('sp') {
      margin-top: 58px;
      margin-bottom: 30px;
    }
    &_area {
    }
    &--item {
      width: 360px;
      min-height: 300px;
      position: relative;
      background-size: cover;
      background-position: center;
      margin: 0 10px;
      float: left;
      /*
			@include res('sp') {
				width: 100%;
				float: none;
				margin: 0;
				height: auto;
			}
			&:not(:last-child) {
				@include res('sp') {
					margin-bottom: 57px;
				}
			}
			*/
      @include res('sp') {
        margin: 0 20px;
        width: calc(100vw - 40px);
      }
      a {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        &:hover {
          .sec_blog_list--item_img {
            &:before {
              opacity: 1;
            }
          }
          .sec_blog_list--item_title,
          .sec_blog_list--item_description,
          .sec_blog_list--item_category {
            text-decoration: underline;
          }
        }
      }
      &_img {
        width: 100%;
        height: 180px;
        background-position: center;
        background-size: cover;
        margin-bottom: 15px;
        border-radius: 10px;
        position: relative;
        &:before {
          content: '';
          display: block;
          opacity: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($orange, 0.9);
          mix-blend-mode: multiply;
          transition: 0.4s;
          border-radius: 10px;
        }
      }
      &_category {
        font-size: 12px;
        letter-spacing: 0.05em;
        font-weight: bold;
        color: $orange;
        margin-bottom: 11px;
      }
      &_title {
        font-size: 14px;
        letter-spacing: 0.05em;
        margin-bottom: 6px;
        @include fontJp();
        font-weight: 700;
        @include res('sp') {
          margin-bottom: 16px;
        }
      }
      &_description {
        font-size: 12px;
        letter-spacing: 0.05em;
        @include fontJp();
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.8em;
        @include res('sp') {
          font-size: 12px;
        }
      }
    }
  }
  &_scroll {
    height: 10px;
    background-color: $grey;
    @include res('pc') {
      width: calc(100% - 80px);
      max-width: 1000px;
      margin: 0 auto;
      margin-bottom: 60px;
    }
    @include res('sp') {
      margin-left: 20px;
      margin-right: 20px;
    }
    /*
		@include res('sp') {
			display: none;
		}
		*/
    &_thumb {
      width: 500px;
      height: 100%;
      background-color: $orange;
    }
  }
}

.sec_blog--recommend {
  padding: 0;
  position: relative;
  z-index: 2;
  &_title {
    height: 105px;
    padding-top: 30px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    background-color: $white;
    @include res('pc') {
      width: calc(100% - 200px);
      max-width: 900px;
      margin: 0 auto;
      margin-top: -60px;
    }
    @include res('sp') {
      width: calc(100% - 20px);
      margin: 0 auto;
      margin-top: -40px;
    }
    &--en {
      font-size: 38px;
      letter-spacing: 0.05em;
      margin-bottom: 20px;
      font-weight: bold;
    }
    &--jp {
      font-size: 12px;
      letter-spacing: 0.1em;
    }
  }
  .sec_blog_list {
    margin-top: 25px;
    @include res('sp') {
      margin-top: 35px;
      margin-bottom: 20px;
      // padding: 0 20px;
    }
  }
  .sec_blog_scroll {
    margin-bottom: 0;
  }
  .link {
    display: none;
  }

  @include res('sp') {
    margin-bottom: 30px;
  }
}

.blog {
  &_main {
    padding-top: 50px;
    padding-bottom: 88px;
    @include res('sp') {
      padding-top: 0;
      padding-bottom: 54px;
    }
    &_content {
      @include res('pc') {
        max-width: 1080px;
        padding: 0 40px;
        margin: 0 auto;
      }
    }
  }
  &_category_nav {
    margin-bottom: 80px;
    @include res('sp') {
      margin-bottom: 50px;
    }
    &_title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    &_list {
      text-align: center;
      @include res('pc') {
        max-width: 500px;
        margin: 0 auto;
        padding: 0 40px;
      }
      a {
        @include fontJp();
        font-size: 14px;
        letter-spacing: 0.1em;
        font-weight: 700;
        transition: 0.4s;
        &:not(:last-child) {
          &:after {
            content: ' | ';
            color: $brown !important;
          }
        }
        &:hover {
          @include res('pc') {
            color: $orange;
          }
        }
        &.active {
          color: $orange;
          pointer-events: none;
        }
      }
    }
  }
  .blog_tag_nav {
    text-align: center;
    padding-top: 30px;
    margin-bottom: 25px;
    @include res('sp') {
      margin-bottom: 35px;
    }
    &_title {
      font-size: 38px;
      letter-spacing: 0.05em;
      margin-bottom: 15px;
      font-weight: bold;
    }
    &_text {
      text-align: center;
      font-size: 12px;
      @include fontJp();
      font-weight: 800;
      letter-spacing: 0.1em;
    }
  }
  &_list {
    flex-wrap: wrap;
    align-items: flex-start;
    @include res('pc') {
      max-width: 1080px;
      padding: 0 40px;
      margin: 0 auto;
    }
    &--item {
      width: calc((100% - 20px) / 2);
      min-height: 335px;
      position: relative;
      background-size: cover;
      background-position: center;
      @include res('sp') {
        width: 100%;
        float: none;
        margin: 0;
        height: auto;
      }
      &:nth-child(n + 3) {
        @include res('pc') {
          margin-top: 40px;
        }
      }
      &:not(:last-child) {
        @include res('sp') {
          margin-bottom: 57px;
        }
      }
      &:hover {
        .blog_list--item_img {
          &:before {
            opacity: 1;
          }
        }
        .blog_list--item_title,
        .blog_list--item_description,
        .blog_list--item_category {
          text-decoration: underline;
        }
      }
      // a {
      // 	width: 100%;
      // 	height: 100%;
      // 	position: relative;
      // 	z-index: 2;

      // }
      &_img {
        width: 100%;
        height: 240px;
        background-position: center;
        background-size: cover;
        margin-bottom: 15px;
        border-radius: 10px;
        position: relative;
        &:before {
          content: '';
          display: block;
          opacity: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($orange, 0.9);
          mix-blend-mode: multiply;
          transition: 0.4s;
          border-radius: 10px;
        }
      }
      &_category {
        font-size: 12px;
        letter-spacing: 0.05em;
        font-weight: bold;
        color: $orange;
        margin-bottom: 11px;
      }
      &_title {
        font-size: 14px;
        letter-spacing: 0.05em;
        margin-bottom: 6px;
        @include fontJp();
        font-weight: 700;
        @include res('sp') {
          margin-bottom: 16px;
        }
      }
      &_description {
        font-size: 12px;
        letter-spacing: 0.05em;
        @include fontJp();
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.8em;
      }
    }
    &_nav {
      width: 100%;
      height: 60px;
      margin-top: 50px;
      position: relative;
      @include res('sp') {
        margin-top: 60px;
      }
      .page-numbers {
        position: absolute;
        height: 60px;
        top: 0;
        color: $orange;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(.prev):not(.next):not(.current) {
          display: none;
        }
        &.current {
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          letter-spacing: 0.05em;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          @include res('sp') {
            width: 80px;
          }
        }
        &.next,
        &.prev {
          border: 1px solid $orange;
          border-radius: 7px;
          text-align: center;
          position: absolute;
          font-size: 16px;
          letter-spacing: 0.05em;
          transition: 0.4s;
          @include res('pc') {
            width: calc((100% - 120px) / 2);
          }
          @include res('sp') {
            width: calc((100% - 80px) / 2);
            color: rgba($orange, 0);
          }
          &:before {
            display: flex;
            width: 100%;
            height: 100%;
            color: $white;
            opacity: 0;
            pointer-events: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.4s;
          }
          &:after {
            content: '';
            display: block;
            width: 14px;
            height: 12px;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            transition: 0.4s;
          }
        }
        &.next {
          right: 0;
          &:before {
            content: '次へ';
          }
          &:hover {
            @include res('pc') {
              background-color: $orange;
              color: rgba($orange, 0);
              &:before {
                opacity: 1;
              }
              &:after {
                background-image: url(../img/arrow-single--white--right@2x.png);
              }
            }
          }
          &:after {
            background-image: url(../img/arrow-single--orange--right@2x.png);
            right: 20px;
            @include res('sp') {
              right: 50%;
              transform: translateX(50%);
            }
          }
        }
        &.prev {
          left: 0;
          &:before {
            content: '戻る';
          }
          &:hover {
            @include res('pc') {
              background-color: $orange;
              color: rgba($orange, 0);
              &:before {
                opacity: 1;
              }
              &:after {
                background-image: url(../img/arrow-single--white--left@2x.png);
              }
            }
          }
          &:after {
            background-image: url(../img/arrow-single--orange--left@2x.png);
            left: 20px;
            @include res('sp') {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
}
.blog-archive {
  .sec_service {
    padding-top: 0;
  }
}
.screen-reader-text {
  display: none !important;
}

.blog_list--search-result {
  margin-bottom: 110px;
}

.blog {
  &_detail {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 2;
    &_content {
      @include res('pc') {
        max-width: 980px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
    &_title {
      padding-top: 30px;
      text-align: center;
      border-radius: 10px 10px 0 0;
      background-color: $white;
      @include res('pc') {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-top: -60px;
        margin-bottom: 43px;
      }
      @include res('sp') {
        width: calc(100% - 20px);
        margin: 0 auto;
        margin-top: -40px;
        margin-bottom: 12px;
      }
      &--category {
        @include fontJp();
        color: $orange;
        font-size: 12px;
        letter-spacing: 0.05em;
        margin-bottom: 20px;
      }
      &--main {
        @include fontJp();
        font-size: 24px;
        letter-spacing: 0.1em;
        font-weight: 700;
        @include res('sp') {
          font-size: 20px;
          text-align: left;
          padding: 0 10px;
        }
      }
    }
    &_area {
      @include fontJp();
      margin-bottom: 80px;

      @include res('sp') {
        margin-bottom: 40px;
      }

      &_img {
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 30px;

        img {
          width: 100%;
          height: auto;
          display: block;
          border-radius: 10px;
          margin: 0 !important;
        }
        @include res('sp') {
          margin-bottom: 10px;
        }
      }
      h2 {
        padding: 20px;
        background-color: $orange;
        border-radius: 7px;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.05em;
        color: $white;
        margin: 30px 0;
        @include res('sp') {
          margin: 15px 0;
        }
      }
      h3 {
        padding: 20px;
        border: 1px solid $brown;
        border-radius: 7px;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.05em;
        margin: 30px 0;
        @include res('sp') {
          margin: 15px 0;
        }
      }
      p {
        font-size: 14px;
        letter-spacing: 0.05em;
      }
      img {
        width: 100%;
        height: auto;
        margin: 30px 0;
        @include res('sp') {
          margin: 15px 0;
        }
      }
      span {
        color: $orange !important;
      }
      strong {
        font-weight: 700;
        color: $orange !important;
      }
      iframe {
        width: 100%;
        height: auto;
        margin: 30px 0;
        // min-height: 500px;
        @include res('sp') {
          margin: 15px 0;
        }
      }
      blockquote {
        padding: 28px 20px;
        background-color: $grey;
        border-radius: 7px;
        margin: 30px 0;
        @include res('sp') {
          margin: 15px 0;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.05em;
        }
      }
      a {
        color: $orange;
        text-decoration: underline;
        &:hover {
          @include res('pc') {
            text-decoration: none;
          }
        }
      }
      ol {
        counter-reset: item;
        li {
          position: relative;
          padding-left: 40px;
          &:before {
            counter-increment: item;
            content: counter(item);
            font-size: 14px;
            color: $white;
            width: 30px;
            height: 30px;
            position: absolute;
            text-align: center;
            left: 0;
            top: 0;
            background-color: $orange;
            border-radius: 7px;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
    &_conversion {
      margin-bottom: 80px;

      .link_button {
        height: 80px;
      }

      @include res('sp') {
        margin-bottom: 40px;
      }
    }
    &_option {
      height: 40px;
      justify-content: flex-start;
      &_title {
        color: $white;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.05em;
        width: 100px;
        height: 40px;
        background-color: $orange;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-right: 15px;
        border-radius: 7px;
      }
      &--share {
        margin-bottom: 20px;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: $orange;
          border-radius: 50%;
          transition: 0.4s;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &:hover {
            @include res('pc') {
              background-color: $brown;
            }
          }
        }
      }
      &--tags {
        height: auto;
        align-items: flex-start;
        .blog_detail_option_title {
          margin-right: 10px;
        }
        .blog_detail_option--tags_list {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          a {
            color: $orange;
            @include fontJp();
            font-weight: 700;
            padding: 13px 18px;
            border: 1px solid $orange;
            border-radius: 7px;
            letter-spacing: 0.1em;
            transition: 0.4s;
            line-height: 0.8em;
            &:not(:last-child) {
              margin-right: 10px;
            }
            @include res('sp') {
              padding: 13px 12px;
              margin-bottom: 10px;
            }
            &:hover {
              @include res('pc') {
                background-color: $orange;
                color: $white;
              }
            }
          }
        }
      }
    }
    &_profile {
      padding-top: 60px;
      margin-top: 30px;
      border-top: 1px solid $brown;
      @include res('sp') {
        align-items: flex-start;
        margin-top: 20px;
        padding-top: 30px;
      }
      &_img {
        margin-right: 20px;
        img {
          border-radius: 50%;
        }
      }
      &_text {
        justify-content: flex-start;
        width: calc(100% - 150px);
        @include res('sp') {
          padding-top: 8px;
        }
        p {
          @include fontJp();
          text-align: left;
          &.text__name {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.05em;
          }
          &.text__description {
            margin-top: 15px;
            font-size: 12px;
            letter-spacing: 0.1em;
          }
        }
      }
    }
    &_nav {
      width: calc(100% + 100px);
      position: relative;
      left: -50px;
      height: 60px;
      margin-top: 53px;
      @include res('pc') {
        @include res('<', 1200) {
          width: calc(100%);
          left: 0;
        }
      }
      @include res('sp') {
        width: 100%;
        left: auto;
        margin-top: 60px;
      }
      .all {
        width: 80px;
        height: 60px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $orange;
        border-radius: 7px;
        color: $orange;
        transition: 0.4s;
        @include res('sp') {
          width: 60px;
        }
        &:hover {
          @include res('pc') {
            background-color: $orange;
            color: rgba($orange, 0);
            &:before {
              opacity: 1;
            }
          }
        }
        &:before {
          content: '一覧へ';
          display: flex;
          width: 100%;
          height: 100%;
          color: $white;
          opacity: 0;
          pointer-events: none;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.4s;
        }
      }
      &--item {
        position: absolute;
        height: 60px;
        top: 0;
        color: $orange;
        display: flex;
        align-items: center;
        justify-content: center;
        &--next,
        &--prev {
          border: 1px solid $orange;
          border-radius: 7px;
          text-align: center;
          position: absolute;
          font-size: 16px;
          letter-spacing: 0.05em;
          transition: 0.4s;
          @include res('pc') {
            width: calc((100% - 120px) / 2);
          }
          @include res('sp') {
            width: calc((100% - 80px) / 2);
            color: rgba($orange, 0);
          }
          &:before {
            display: flex;
            width: 100%;
            height: 100%;
            color: $white;
            opacity: 0;
            pointer-events: none;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.4s;
          }
          &:after {
            content: '';
            display: block;
            width: 14px;
            height: 12px;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            transition: 0.4s;
          }
        }
        &--next {
          right: 0;
          &:before {
            content: '次へ';
          }
          &:hover {
            @include res('pc') {
              background-color: $orange;
              color: rgba($orange, 0);
              &:before {
                opacity: 1;
              }
              &:after {
                background-image: url(../img/arrow-single--white--right@2x.png);
              }
            }
          }
          &:after {
            background-image: url(../img/arrow-single--orange--right@2x.png);
            right: 20px;
            @include res('sp') {
              right: 50%;
              transform: translateX(50%);
            }
          }
        }
        &--prev {
          left: 0;
          &:before {
            content: '戻る';
          }
          &:hover {
            @include res('pc') {
              background-color: $orange;
              color: rgba($orange, 0);
              &:before {
                opacity: 1;
              }
              &:after {
                background-image: url(../img/arrow-single--white--left@2x.png);
              }
            }
          }
          &:after {
            background-image: url(../img/arrow-single--orange--left@2x.png);
            left: 20px;
            @include res('sp') {
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
    .blog_category_nav {
      margin-top: 60px;
    }
  }
}

.sec_contact {
  width: 100%;
  position: relative;
  padding-bottom: 170px;
  @include res('sp') {
    padding-top: 86px;
  }
  &_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
    }
    &:before {
      height: 100px;
      background-size: 154px 100px;
      background-image: url(../img/dia_bg--grey@2x.png);
      top: 0;
      background-position: center;
    }
    &:after {
      height: calc(100% - 50px);
      top: 50px;
      background-color: $grey;
    }
  }
  &_content {
    @include res('pc') {
      max-width: 780px;
      margin: 0 auto;
      padding: 0 40px;
    }
    .sec_contact_form {
      margin-top: 54px;
      @include res('sp') {
        width: 100%;
        margin-top: 40px;
      }
      br {
        display: none !important;
      }
      .contact-form {
        margin-bottom: 20px;
      }
      select,
      input,
      textarea {
        background-color: $white;
        border: 1px solid $darkGrey;
        border-radius: 4px;
        width: 100%;
        height: 30px;
        text-align: center;
        color: $brown;
        font-size: 14px;
        letter-spacing: 0.1em;
        padding: 6px;
        &::placeholder {
          color: rgba($black, 0.2);
        }
      }
      textarea {
        height: 130px;
        resize: none;
      }
      &--select {
        position: relative;
        .sec_contact_form--select_label {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          text-align: center;
          z-index: 2;
          font-size: 14px;
          letter-spacing: 0.1em;
          pointer-events: none;
          p {
            vertical-align: middle;
            &:after {
              content: '';
              display: inline-block;
              width: 10px;
              height: 5px;
              background-image: url(../img/arrow--form@2x.png);
              background-size: cover;
              margin-left: 17px;
              position: relative;
              top: -2px;
            }
          }
        }
        .wpcf7-not-valid-tip {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          margin-top: 0 !important;
        }
        select {
          color: rgba($brown, 0);
        }
        &--02 {
          @include res('pc') {
            width: calc((100% - 20px) / 2);
          }
        }
      }
      .contact-form-textarea {
        margin-bottom: 50px;
      }
      .contact-form-button {
        width: 250px;
        height: 60px;
        margin: 0 auto;
        @include res('sp') {
          width: 100%;
        }
        input {
          width: 100%;
          height: 100%;
          padding: 0;
          background: transparent;
          border: none;
          border-radius: 0;
          font-size: 16px;
          letter-spacing: 0.05em;
          font-family: $font-family;
          color: $white;
          transition: 0.4s;
          text-transform: uppercase;
          &:hover {
            cursor: pointer;
            color: $blue;
          }
        }
      }
      .ajax-loader {
        display: none !important;
      }
      .wpcf7-not-valid-tip {
        font-size: 10px;
        color: $orange;
        margin-top: 10px;
      }
      .wpcf7-response-output {
        padding: 0;
        margin: 0;
        border: none;
        margin-top: 20px;
        color: $orange;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-align: center;
      }
    }
  }
}

span.wpcf7-spinner {
  display: none !important;
}

.lower_header {
  & + .qa_search {
    margin-top: -60px;
    @include res('sp') {
      margin-top: -40px;
    }
  }
}

.qa {
  &_search {
    @include res('pc') {
      max-width: 900px;
      width: calc(100% - 200px);
    }
    height: 70px;
    margin: 0 auto;
    background-color: $white;
    padding: 40px 40px 0;
    position: relative;
    z-index: 3;
    border-radius: 10px 10px 0 0;
    @include res('sp') {
      width: calc(100% - 20px);
      height: 60px;
      padding: 30px 20px 0;
    }
    &_form {
      width: 100%;
      height: 100%;
      form {
        width: 100%;
        height: 100%;
        position: relative;
        .search-btn {
          position: absolute;
          width: 33px;
          height: 100%;
          right: 0;
          top: 0;
          background-image: url(../img/icon--search@2x.png);
          background-size: 16px 16px;
          background-repeat: no-repeat;
          background-position: center;
        }
        input {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          border: 1px solid $orange;
          font-size: 14px;
          padding: 7px;
          @include fontJp();
          color: $brown;
        }
      }
    }
  }
}

.single-qa {
  .qa_search {
    padding-top: 52px;
    height: 82px;
  }
}

.qa {
  &_list {
    padding-top: 50px;
    padding-bottom: 110px;
    flex-wrap: wrap;
    align-items: flex-start;
    @include res('pc') {
      max-width: 1080px;
      margin: 0 auto;
      padding-left: 40px;
      padding-right: 40px;
    }
    @include res('sp') {
      width: 100%;
      padding: 30px 20px 50px;
    }
    &--item {
      width: calc((100% - 20px) / 2);
      @include res('sp') {
        width: 100%;
      }
      &:nth-child(n + 3) {
        @include res('pc') {
          margin-top: 18px;
        }
      }
      &:not(:last-child) {
        @include res('sp') {
          margin-bottom: 10px;
        }
      }
      &.active {
        .qa_list--item_category {
          background-color: $brown;
          color: $white;
          .arrow {
            transform: rotate(180deg) translateY(calc(-50% + 12px));
            background-image: url(../img/arrow-single--white--down@2x.png);
          }
        }
      }
      &_category {
        background-color: $grey;
        width: 100%;
        height: 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 14px;
        @include fontJp();
        letter-spacing: 0.1em;
        transition: 0.4s;
        .arrow {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.4s;
        }
        &:hover {
          cursor: pointer;
          background-color: $brown;
          color: $white;
          .arrow {
            background-image: url(../img/arrow-single--white--down@2x.png);
          }
        }
      }
      &_post {
        display: none;
        margin-top: 33px;
        @include res('sp') {
          margin-bottom: 20px;
        }
        &--item {
          display: block;
          position: relative;
          font-size: 16px;
          letter-spacing: 0.05em;
          transition: 0.4s;
          &:not(:last-child) {
            margin-bottom: 30px;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 6px;
            border-color: transparent transparent transparent $brown;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.4s;
          }
          &:hover {
            @include res('pc') {
              color: $orange;
              &:after {
                border-color: transparent transparent transparent $orange;
              }
            }
          }
        }
      }
    }
  }
  &_content {
    padding-top: 0;
    padding-bottom: 45px;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid $brown;
    margin: 0 auto;
    @include res('pc') {
      max-width: 1000px;
      padding-left: 40px;
      padding-right: 40px;
      @include res('<', 1200) {
        padding-left: 0;
        padding-right: 0;
        width: calc(100% - 80px);
      }
    }
    @include res('sp') {
      width: calc(100% - 20px);
    }
    &_title {
      padding: 32px 20px 0;
      border-radius: 10px 10px 0 0;
      background-color: $white;
      @include res('pc') {
        max-width: 900px;
        margin: 0 auto;
        margin-top: -60px;
        margin-bottom: 46px;
      }
      @include res('sp') {
        padding: 20px 10px 0;
        margin-top: -40px;
        margin-bottom: 30px;
      }
      &_category {
        text-align: center;
        margin-bottom: 24px;
        @include res('sp') {
          margin-bottom: 13px;
          text-align: left;
        }
        a,
        span {
          font-size: 12px;
          letter-spacing: 0.05em;
          @include fontJp();
          @include res('sp') {
            font-weight: bold;
          }
          &:first-child {
            color: $orange;
          }
          &:not(:last-child) {
            &:after {
              content: '＞';
              font-size: 12px;
              letter-spacing: 0.05em;
            }
          }
        }
        a {
          &:hover {
            @include res('pc') {
              color: $orange;
              text-decoration: underline;
            }
          }
        }
      }
      &_name {
        font-size: 24px;
        letter-spacing: 0.1em;
        @include fontJp();
        font-weight: 700;
        text-align: center;
        @include res('sp') {
          font-size: 20px;
          text-align: left;
        }
      }
    }
    &_main {
      padding: 0 50px;
      @include fontJp();
      @include res('pc') {
        max-width: 900px;
        margin: 0 auto;
      }
      @include res('sp') {
        width: 100%;
        padding: 0;
      }
      h2 {
        padding: 21px;
        font-size: 18px;
        color: $white;
        letter-spacing: 0.05em;
        font-weight: 700;
        background-color: $orange;
        border-radius: 7px;
        & + * {
          margin-top: 30px;
        }
      }
      h3 {
        padding: 20px;
        border: 1px solid $brown;
        border-radius: 7px;
        font-size: 18px;
        letter-spacing: 0.05em;
        font-weight: 700;
      }
      h4 {
        background-color: $grey;
        border-radius: 7px;
        letter-spacing: 0.05em;
        padding: 27px 20px;
        font-size: 14px;
        font-weight: 700;
        strong {
          color: $orange;
          letter-spacing: 0.05em;
          font-weight: 700;
        }
      }
      p {
        font-size: 14px;
        letter-spacing: 0.05em;
      }
      strong {
        font-weight: bold;
      }
      ul {
        li {
          font-size: 14px;
          letter-spacing: 0.05em;
          list-style-type: disc;
          list-style-position: inside;
        }
      }
      * + h2 {
        margin-top: 50px;
        @include res('sp') {
          margin-top: 36px;
        }
      }
      * + h3 {
        margin-top: 50px;
        @include res('sp') {
          margin-top: 36px;
        }
      }
      * + h4 {
        margin-top: 50px;
        @include res('sp') {
          margin-top: 36px;
        }
      }
      * + p {
        margin-top: 30px;
        @include res('sp') {
          margin-top: 10px;
        }
      }
      * + ul {
        margin-top: 30px;
        @include res('sp') {
          margin-top: 10px;
        }
      }
      blockquote {
        padding: 28px 20px;
        background-color: $grey;
        border-radius: 7px;
        margin: 30px 0;
        @include res('sp') {
          margin: 15px 0;
        }
        p {
          font-size: 14px;
          letter-spacing: 0.05em;
        }
      }
      a {
        color: $orange;
        &:hover {
          @include res('pc') {
            text-decoration: underline;
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }
      ol {
        counter-reset: item;
        li {
          position: relative;
          padding-left: 40px;
          &:before {
            counter-increment: item;
            content: counter(item);
            font-size: 14px;
            color: $white;
            width: 30px;
            height: 30px;
            position: absolute;
            text-align: center;
            left: 0;
            top: 0;
            background-color: $orange;
            border-radius: 7px;
          }
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.search.post-type-archive-qa {
}

.search_result {
  padding-top: 40px;
  padding-bottom: 20px;
  @include res('pc') {
    max-width: 1080px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
  }
  @include res('sp') {
    width: 100%;
    padding: 30px 20px 50px;
  }
  &_title {
    text-align: center;
    @include fontJp();
    font-size: 24px;
    letter-spacing: 0.1em;
    margin-bottom: 44px;
    font-weight: 800;
  }
  &_count {
    font-size: 16px;
    letter-spacing: 0.05em;
    margin-bottom: 64px;
    @include fontJp();
    @include res('sp') {
      margin-bottom: 34px;
    }
  }
  &_list {
    padding: 50px 0;
    border-top: 1px solid $brown;
    border-bottom: 1px solid $brown;
    &--item {
      display: block;
      @include fontJp();
      font-size: 16px;
      position: relative;
      letter-spacing: 0.05em;
      transition: 0.4s;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 0 4px 6px;
        border-color: transparent transparent transparent $brown;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.4s;
      }
      &:hover {
        @include res('pc') {
          color: $orange;
          &:after {
            border-color: transparent transparent transparent $orange;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.search_result_count--blog {
  @include res('pc') {
    padding-left: 40px;
  }
}

.guide {
  &_process {
    background-color: $grey;
    @include res('sp') {
      padding-bottom: 127px;
    }
  }
}

.flexidisc {
  &_about {
    &_content {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
    &_description {
      @include fontJp();
      letter-spacing: 0.1em;
      margin-bottom: 52px;
      text-align: center;
      @include res('sp') {
        margin-bottom: 56px;
        text-align: left;
      }
    }
    &_list {
      align-items: flex-start;
      @include res('pc') {
        max-width: 805px;
        margin: 0 auto;
        padding: 0 40px;
      }
      @include res('sp') {
        width: 294px;
        flex-wrap: wrap;
        margin: 0 auto;
      }
      &--item {
        &:nth-child(n + 3) {
          @include res('sp') {
            margin-top: 53px;
          }
        }
        &_img {
          width: 127px;
          height: 138px;
          background-image: url(../img/fd_icon@2x.png);
          background-size: cover;
          margin-bottom: 23px;
          p {
            font-size: 16px;
            letter-spacing: 0.05em;
            padding-top: 88px;
            text-align: center;
            font-weight: bold;
            line-height: 1.25em;
          }
        }
        &_text {
          font-size: 12px;
          letter-spacing: 0.1em;
          text-align: center;
          line-height: 1.8em;
        }
      }
    }
  }
  &_price {
    background-color: $grey;
    &_content {
      @include res('pc') {
        max-width: 880px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
    &_list {
      margin-top: 23px;
      @include res('sp') {
        margin-top: 47px;
      }
      &--item {
        &:not(:first-child) {
          margin-top: 50px;
        }
        &_title {
          @include fontJp();
          font-size: 16px;
          letter-spacing: 0.1em;
          margin-bottom: 15px;
        }
        table {
          width: 100%;
          table-layout: fixed;
          tr {
            height: 60px;
            &:first-child {
              background-color: $brown;
              td {
                font-size: 13px;
                color: $white;
                &:first-child {
                  border-radius: 10px 0 0 0;
                }
                &:last-child {
                  border-radius: 0 10px 0 0;
                }
              }
            }
            &:last-child {
              td {
                &:first-child {
                  border-radius: 0 0 0 10px;
                }
                &:last-child {
                  border-radius: 0 0 10px 0;
                }
              }
            }
            &:not(:first-child) {
              td {
                &:first-child {
                  background-color: $orange;
                  color: $white;
                }
                &:not(:first-child) {
                  background-color: $white;
                }
              }
            }
            td {
              vertical-align: middle;
              @include fontJp();
              letter-spacing: 0.1em;
              text-align: center;
            }
          }
        }
        .table--has-border {
          /*
          border: 1px solid $brown;
          overflow: hidden;
          border-radius: 10px 10px;
          */
          border-collapse: separate;

          tr:first-child {
            border-radius: 10px 10px 0 0;
            border-top: 1px solid $brown;
            border-left: 1px solid $brown;
            border-right: 1px solid $brown;
          }
          tr:not(:first-child) {
            td:first-child {
              border-left: 1px solid $brown;
            }
            td:not(:first-child) {
              border-left: 1px solid $brown;
              border-right: 1px solid $brown;
            }
          }
          tr {
            td {
              border-bottom: 1px solid $brown;
            }
          }
        }
      }
      &--item--cutting {
        @include res('sp') {
          font-size: 13px;
          line-height: 1.5;
        }
        &:not(:first-child) {
          @include res('sp') {
            margin-top: 26px;
          }
        }
      }
    }
  }
  &_color {
    &_content {
      @include res('pc') {
        max-width: 1040px;
        margin: 0 auto;
        padding: 0 40px;
      }
      &--item {
        &:not(:last-child) {
          margin-bottom: 127px;
          @include res('sp') {
            margin-bottom: 57px;
          }
        }
        &_title {
          @include fontJp();
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 21px;
          @include res('sp') {
            text-align: center;
            margin-bottom: 20px;
          }
        }
        &_description {
          @include fontJp();
          font-size: 16px;
          letter-spacing: 0.1em;
          margin-bottom: 36px;
          @include res('sp') {
            margin-bottom: 52px;
          }
          a {
            color: $orange;
            &:hover {
              @include res('pc') {
                text-decoration: underline;
              }
            }
          }
        }
        &:first-child {
          .flexidisc_color_content--item_img {
            flex-wrap: wrap;
            @include res('pc') {
              max-width: 780px;
              margin: 0 auto;
            }
            @include res('sp') {
              width: 100%;
              padding: 0 10px;
            }
            img {
              display: block;
              @include res('sp') {
                width: calc((100% - 20px) / 2);
                height: auto;
              }
              &:nth-child(n + 5) {
                @include res('pc') {
                  margin-top: 20px;
                }
              }
              &:nth-child(n + 3) {
                @include res('sp') {
                  margin-top: 20px;
                }
              }
            }
          }
        }
        &:nth-child(2) {
          .flexidisc_color_content--item_img {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.sec_contact--flexidisc {
  .sec_contact_form {
    .sec_contact_form--select--02 {
      @include res('pc') {
        &:nth-child(odd) {
          float: left;
        }
        &:nth-child(even) {
          float: right;
        }
      }
    }
  }
}

.about {
  &_intro {
    &_content {
      @include res('pc') {
        max-width: 1120px;
        margin: 0 auto;
        padding: 0 40px;
        @include res('<', 1278) {
          br {
            display: none;
          }
        }
      }
    }
    &_description {
      letter-spacing: 0.1em;
      text-align: center;
      margin-bottom: 100px;
      @include res('pc') {
        width: calc(100% + 50px);
        margin-left: -25px;
      }
      @include res('sp') {
        margin-bottom: 102px;
        text-align: left;
      }
    }
  }
  &_showroom {
    background-color: $grey;
    &_list {
      margin-top: 54px;
      margin-bottom: 46px;
      width: 100%;
      overflow: scroll;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include res('sp') {
        margin-top: 58px;
        margin-bottom: 58px;
      }
      &_area {
      }
      &--item {
        width: 360px;
        min-height: 240px;
        position: relative;
        background-size: cover;
        background-position: center;
        margin: 0 10px;
        float: left;
        @include res('sp') {
          width: 100%;
          float: none;
          margin: 0;
          height: auto;
        }
        &:not(:last-child) {
          @include res('sp') {
            margin-bottom: 20px;
          }
        }
        a {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
        }
        &_img {
          width: 100%;
          height: 240px;
          background-position: center;
          background-size: cover;
          border-radius: 10px;
        }
      }
    }
    &_scroll {
      height: 10px;
      background-color: $white;
      @include res('pc') {
        width: calc(100% - 80px);
        max-width: 1000px;
        margin: 0 auto;
      }
      @include res('sp') {
        display: none;
      }
      &_thumb {
        width: 500px;
        height: 100%;
        background-color: $orange;
      }
    }
  }
  &_member {
    &_list {
      flex-wrap: wrap;

      @include res('pc') {
        max-width: 940px;
        margin: 58px auto 0;
        padding: 0 40px;
        justify-content: flex-start;
        align-items: flex-start;
      }

      @include res('sp') {
        margin-top: 70px;
        width: 100%;
        padding: 0 10px;
        align-items: flex-start;
      }
      &--item {
        width: calc((100% - 60px) / 4);
        @include res('sp') {
          width: calc((100% - 20px) / 2);
        }
        &:not(:nth-child(4n)) {
          @include res('pc') {
            margin-right: 20px;
          }
        }
        &:nth-child(n + 5) {
          @include res('pc') {
            margin-top: 50px;
          }
        }
        &:nth-child(n + 3) {
          @include res('sp') {
            margin-top: 55px;
          }
        }
        &_img {
          width: 100%;
          height: 190px;
          margin-bottom: 12px;
          border-radius: 10px;
          background-size: cover;
          background-position: center;
          @include res('sp') {
            height: 160px;
            margin-bottom: 18px;
          }
        }
        &_position--en {
          font-size: 16px;
          letter-spacing: 0.05em;
          font-weight: bold;
          margin-bottom: 9px;
          line-height: 1em;
        }
        .Img + &_position--en {
          margin-top: 9px;
        }
        &_position--jp {
          font-size: 14px;
          @include fontJp();
          letter-spacing: 0.1em;
          margin-bottom: 12px;
          line-height: 1em;
        }
        &_name {
          font-size: 14px;
          @include fontJp();
          letter-spacing: 0.1em;
          line-height: 1em;
        }
      }
    }
  }
  &_map {
    padding: 0;
    &_area {
      width: 100%;
      height: 400px;
      position: relative;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  &_corporate {
    &_content {
      @include res('pc') {
        max-width: 1000px;
        margin: 0 auto;
        padding-left: 100px;
        padding-right: 120px;
      }
      align-items: flex-start;
      @include res('sp') {
        flex-direction: column;
      }
    }
    &_logo {
      @include res('pc') {
        margin-right: 50px;
      }
      @include res('sp') {
        margin: 0 auto;
        margin-bottom: 70px;
      }
    }
    &_text {
      @include res('pc') {
        flex: 1;
      }
      @include res('sp') {
        width: 100%;
      }
      &--item {
        align-items: flex-start;
        position: relative;
        @include res('sp') {
          flex-direction: column;
        }
        &:not(:last-child) {
          @include res('sp') {
            margin-bottom: 16px;
          }
        }
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $brown;
          top: 0;
          left: 223px;
          @include res('sp') {
            display: none;
          }
        }
        &_name {
          width: 185px;
          @include fontJp();
          font-size: 14px;
          letter-spacing: 0.1em;
          font-weight: 700;
          margin-right: 60px;
          @include res('sp') {
            width: 100%;
          }
        }
        &_detail {
          flex: 1;
          @include fontJp();
          font-size: 14px;
          letter-spacing: 0.1em;
          a {
            color: $orange;
            &:hover {
              @include res('pc') {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.record-gallery {
  &_main {
    &_content {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
  }
  &_list {
    flex-wrap: wrap;
    &--item {
      width: calc((100% - 20px) / 2);
      height: 240px;
      border-radius: 10px;
      position: relative;
      background-size: cover;
      background-position: center;
      @include res('sp') {
        width: 100%;
        height: 180px;
        margin: 0;
      }
      &:not(:last-child) {
        @include res('sp') {
          margin-bottom: 10px;
        }
      }
      &:nth-child(n + 3) {
        @include res('pc') {
          margin-top: 20px;
        }
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 10px;
        transition: 0.4s;
      }
      &:hover {
        @include res('pc') {
          &:before {
            background-color: rgba($orange, 0.7);
          }
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: flex-start;
        justify-content: center;
        padding: 0 20px;
        flex-direction: column;
      }
      &_title {
        @include fontJp();
        color: $white;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.05em;
        margin-bottom: 12px;
      }
    }
  }
  &_nav {
    width: 100%;
    height: 60px;
    margin-top: 50px;
    position: relative;
    @include res('sp') {
      margin-top: 10px;
    }
    .page-numbers {
      position: absolute;
      height: 60px;
      top: 0;
      color: $orange;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(.prev):not(.next):not(.current) {
        display: none;
      }
      &.current {
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        letter-spacing: 0.05em;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @include res('sp') {
          width: 80px;
        }
      }
      &.next,
      &.prev {
        border: 1px solid $orange;
        border-radius: 7px;
        text-align: center;
        position: absolute;
        font-size: 16px;
        letter-spacing: 0.05em;
        transition: 0.4s;
        @include res('pc') {
          width: calc((100% - 120px) / 2);
        }
        @include res('sp') {
          width: calc((100% - 80px) / 2);
          color: rgba($orange, 0);
        }
        &:after {
          content: '';
          display: block;
          width: 14px;
          height: 12px;
          background-size: cover;
          background-position: center;
          position: absolute;
          top: 50%;
          margin-top: -6px;
          transition: 0.4s;
        }
      }
      &.next {
        right: 0;
        &:hover {
          @include res('pc') {
            background-color: $orange;
            color: $white;
            &:after {
              background-image: url(../img/arrow-single--white--right@2x.png);
            }
          }
        }
        &:after {
          background-image: url(../img/arrow-single--orange--right@2x.png);
          right: 20px;
          @include res('sp') {
            right: 50%;
            transform: translateX(50%);
          }
        }
      }
      &.prev {
        left: 0;
        &:hover {
          @include res('pc') {
            background-color: $orange;
            color: $white;
            &:after {
              background-image: url(../img/arrow-single--white--left@2x.png);
            }
          }
        }
        &:after {
          background-image: url(../img/arrow-single--orange--left@2x.png);
          left: 20px;
          @include res('sp') {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.term {
  &_main {
    &_content {
      @include res('pc') {
        max-width: 980px;
        margin: 0 auto;
        padding: 0 40px;
      }
      @include fontJp();
      h2 {
        padding: 21px;
        font-size: 18px;
        color: $white;
        letter-spacing: 0.05em;
        font-weight: 700;
        background-color: $orange;
        border-radius: 7px;
        & + * {
          margin-top: 30px;
        }
      }
      p {
        font-size: 14px;
        letter-spacing: 0.05em;
      }
      a {
        color: $orange;
        &:hover {
          @include res('pc') {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.transaction {
  &_main {
    &_content {
      @include res('pc') {
        max-width: 880px;
        margin: 0 auto;
        padding: 0 40px;
      }
      @include fontJp();
      table {
        width: 100%;
        border: 1px solid $brown;
        background-color: $white;
        border-radius: 7px;
        border-collapse: separate;
        tr {
          @include res('pc') {
            height: 60px;
          }
          &:not(:last-child) {
            td {
              &:last-child {
                border-bottom: 1px solid $brown;
              }
            }
          }
          @include res('pc') {
            &:first-child {
              td {
                &:first-child {
                  border-radius: 5px 0 0 0;
                }
                &:last-child {
                  border-radius: 0 5px 0 0;
                }
              }
            }
            &:last-child {
              td {
                &:first-child {
                  border-radius: 0 0 0 5px;
                }
                &:last-child {
                  border-radius: 0 0 5px 0;
                }
              }
            }
          }
          @include res('sp') {
            &:first-child {
              td {
                &:first-child {
                  border-radius: 5px 5px 0 0;
                }
              }
            }
          }
          td {
            font-size: 13px;
            vertical-align: middle;
            @include res('sp') {
              height: 40px;
            }
            &:first-child {
              width: 200px;
              background-color: $brown;
              color: $white;
              text-align: center;
              @include res('sp') {
                width: 100%;
              }
            }
            &:last-child {
              width: calc(100% - 200px);
              padding-left: 30px;
              padding-right: 30px;
              @include res('sp') {
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
              }
            }
          }
        }
      }
      @include res('sp') {
        table,
        tbody,
        thead,
        tfoot,
        tr,
        th,
        td {
          display: block;
        }
        td {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.template {
  &_main {
    &_content {
      @include res('pc') {
        max-width: 980px;
        margin: 0 auto;
        padding: 0 40px;
      }
    }
  }
  &_group {
    width: 100%;
    &--item {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      &_title {
        padding: 21px;
        font-size: 18px;
        color: $white;
        letter-spacing: 0.05em;
        font-weight: 700;
        background-color: $orange;
        border-radius: 7px;
        margin-bottom: 20px;
      }
      .template_list {
        &--item {
          &:not(:last-child) {
            margin-bottom: 6px;
          }
          a {
            font-size: 14px;
            color: $orange;
            @include fontJp();
            &:before {
              content: '●';
            }
            &:hover {
              @include res('pc') {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.contact {
  &_intro {
    text-align: center;
    padding-bottom: 78px;
    @include res('sp') {
      padding: 40px 0 52px;
    }
    &_tel {
      font-size: 32px;
      letter-spacing: 0.05em;
      margin-bottom: 7px;
      font-weight: bold;
      color: $orange;
    }
    &_text {
      font-size: 14px;
      @include fontJp();
      letter-spacing: 0.1em;
    }
  }
  .sec_contact {
    padding-bottom: 100px;
    .title {
      display: none;
    }
  }
}

.cutting {
  &_main {
    &_content {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    &_content--member {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding-left: 89px;
        padding-right: 89px;
      }
    }
    &_list {
      margin-top: 54px;
      margin-bottom: 60px;
      width: 100%;
      overflow: scroll;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include res('sp') {
        margin-top: 58px;
        margin-bottom: 58px;
      }
      &_area {
      }
      &--item {
        width: 360px;
        min-height: 240px;
        position: relative;
        background-size: cover;
        background-position: center;
        margin: 0 10px;
        float: left;
        @include res('sp') {
          width: 100%;
          float: none;
          margin: 0;
          height: auto;
        }
        &:not(:last-child) {
          @include res('sp') {
            margin-bottom: 20px;
          }
        }
        a {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
        }
        &_img {
          width: 100%;
          height: 180px; //240px;
          background-position: center;
          background-size: cover;
          border-radius: 10px;
        }
      }
    }
    &_scroll {
      height: 10px;
      background-color: $grey;
      @include res('pc') {
        width: calc(100% - 80px);
        max-width: 1000px;
        margin: 0 auto;
      }
      @include res('sp') {
        display: none;
      }
      &_thumb {
        width: 500px;
        height: 100%;
        background-color: $orange;
      }
    }
  }
}

.cutting {
  .sec_blog {
    background-color: $grey;
    padding-bottom: 160px;
  }
  & + .sec_rc {
    margin-top: -50px;
  }
}

.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}

.link_button--h-80 {
  height: 80px;
}

.blog_detail_area {
  .InsertBlock {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .link_button {
    text-decoration: none;

    span.link_button--rc--en,
    span.link_button--rc--jp {
      color: #fff !important;
    }
    span.link_button--rc--en {
      font-family: $font-family;
    }

    &:hover {
      span.link_button--rc--en,
      span.link_button--rc--jp {
        color: #2f1829 !important;
      }
    }
  }
}

.CommonPadding {
  @include res('pc') {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/*
.Search {
	width: 100%;
	height: 30px;

	form {
		width: 100%;
		height: 100%;
	}
}
*/
.SearchBox {
  margin: 0;
  padding: 0;
}

.SearchBox--sp-has-margin-bottom {
  @include res('sp') {
    margin-bottom: 40px;
  }
}

.sec_blog--normal {
  .sec_blog_list {
    @include res('sp') {
      margin-left: -20px;
      width: calc(100% + 40px);
    }
  }
  .sec_blog_scroll {
    @include res('sp') {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}

.sec_blog_scroll--white {
  background-color: #fff;
}
.sec_blog_scroll--no-margin-bottom {
  margin-bottom: 0;
}

.blog_detail_area {
  .Embed--youtube {
    padding-top: 56.25%;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
