.Section {
}

.Section--no-padding-bottom {
  padding-bottom: 0;
}

.Section--gray {
  background-color: #f7f7f7;
}
