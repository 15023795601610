.about {
  &_wwh {
    &--item {
      @include res('pc') {
        max-width: 1080px;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 76px;
        @include res('sp') {
          margin-bottom: 60px;
        }
      }
      .description {
        @include res('pc') {
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

section.about_intro + section.sec_blog {
  background: #f7f7f7;
}
