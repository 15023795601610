.TwotoneList {
  border-radius: 10px 10px;
  border: 1px solid #2f1829;
  overflow: hidden;
}

.title + .TwotoneList {
  margin-top: 12px;
}

.TwotoneListItem {
  display: flex;
  @include fontJp();
  font-size: 13px;
}

.TwotoneListItem + .TwotoneListItem {
  border-top: 1px solid #2f1829;
}

.TwotoneListItem__label {
  width: 30%;
  background-color: #2f1829;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  @include res("sp") {
    width: 35%;
    text-align: center;
  }
}
.TwotoneListItem__value {
  width: 70%;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 18px 30px;

  span {
    line-height: 2;
  }
  @include res("sp") {
    width: 65%;
    padding-right: 20px;
  }
}
