@charset "UTF-8";

/* ////////////////////////////////////////
$color
//////////////////////////////////////// */
$black : #000;
$white : #fff;

$grey: #F7F7F7;
$darkGrey: #D5D5D5;
$cream: #FFFBF5;
$blue: #4A7A87;
$orange: #EC732E;
$brown: #2F1829;


$font-family : 'Quantico','Noto Sans JP',"游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ', Meiryo, 'Helvetica Neue', Helvetica, Arial, 'ＭＳ Ｐゴシック','MS PGothic',sans-serif;
$font-family-jp : 'Noto Sans JP',"游ゴシック体", "Yu Gothic", YuGothic, 'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ', Meiryo, 'Helvetica Neue', Helvetica, Arial, 'ＭＳ Ｐゴシック','MS PGothic',sans-serif;

@mixin fontJp() {
	font-family: $font-family-jp;
	font-weight: 500;
}

/* ////////////////////////////////////////
$breakpoint
//////////////////////////////////////// */
$bp-pc : 960;
$bp-sp : 960;
