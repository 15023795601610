.title + .OrderFlow {
  margin-top: 64px;
  @include res("sp") {
    margin-top: 28px;
  }
}

.OrderFlowItem {
  display: flex;
  @include res("sp") {
    display: block;
  }
}
.OrderFlowItem--left-content {
  flex-direction: row-reverse;
}

.OrderFlowItem__image,
.OrderFlowItem__content {
  width: 50%;
  @include res("sp") {
    width: 100%;
  }
}
.OrderFlowItem__image {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include res("sp") {
    min-height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.OrderFlowItem__content {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;

  @include res("sp") {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
}

.OrderFlowItem__index {
  font-family: $font-family;
  font-weight: bold;
  font-size: 24px;
}
.OrderFlowItem__title {
  font-weight: bold;
  font-size: 24px;
  margin-top: 12px;
  @include res("sp") {
    line-height: 1.55;
  }
}
.OrderFlowItem__text {
  font-size: 16px;
  margin-top: 19px;

  span {
    font-size: 12px;
  }
  @include res("sp") {
    margin-top: 8px;
  }
}
