.PriceOverview {
  margin-top: 20px;

  a {
    text-decoration: underline;
  }
}

.cutting_main_content {
  section:first-child {
    padding-top: 0;
  }
  section + section {
    padding-top: 0;
  }
}
